import React, { Dispatch, SetStateAction } from 'react'
import classes from './styles.module.scss'
import { SettingsType } from 'models/SettingsType'
import { getMessageLogo } from 'helper/getMessageLogo'
import { MessageType } from 'models/MessageType'
import { Message } from 'ai'
import { ChatRoleMap } from 'constants/index'
import QuickReplyList from '../QuickReplyList'
import { Append, QuickReplies } from 'types'
import { WidgetEvent } from 'helper/eventHelper'

interface Props {
  postMessage?: (m: MessageType) => void
  showLogo: boolean
  isLastMessage: boolean
  message: Message
  quickReplies: string[]
  setQuickReplies: Dispatch<SetStateAction<QuickReplies>>
  showQuickReplies: boolean
  settings: SettingsType
  showTimestamp?: boolean
  append: Append
  trackWidgetEvent: (event: WidgetEvent) => void
  setRequestSentAt: Dispatch<SetStateAction<Date | null>>
}

export const MessageWrapper: React.FC<Props> = ({
  showLogo,
  message,
  quickReplies,
  setQuickReplies,
  showQuickReplies,
  settings,
  children,
  isLastMessage,
  append,
  trackWidgetEvent,
  setRequestSentAt,
}) => {
  const getContainerClass = () => {
    const wrapAssistantClass =
      message?.role === ChatRoleMap.assistant && showLogo ? classes.containerWithLogo : classes.assistantContainer

    return message?.role === ChatRoleMap.assistant ? wrapAssistantClass : classes.userContainer
  }

  const isUserMessage = message.role === ChatRoleMap.user

  return (
    <div
      className={`${getContainerClass()} ${showQuickReplies && quickReplies.length ? classes.marginWithQR : ''}`}
      data-autotest={`${isLastMessage ? 'last-message ' : ''}${!isUserMessage ? 'message-received' : 'message-send'}`}>
      <div className={`${classes.msgWrapper} ${isUserMessage ? classes.userMsgWrapper : ''}`}>
        {!isUserMessage ? (
          <img className={classes.logo} src={getMessageLogo(settings)} alt="icon" aria-hidden="true" />
        ) : null}

        <>{children}</>
      </div>

      {showQuickReplies && !!quickReplies.length && (
        <QuickReplyList
          append={append}
          borderRadius={settings.shape?.radius}
          backgroundColor={settings.backgroundColor}
          color={settings.color}
          quickReplies={quickReplies}
          setQuickReplies={setQuickReplies}
          trackWidgetEvent={trackWidgetEvent}
          setRequestSentAt={setRequestSentAt}
        />
      )}
    </div>
  )
}
