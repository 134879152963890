import { useEffect } from 'react'
import { MetricsData } from './useMetrics'

export const useRequestCompleted = (metricsData: MetricsData, doSendMetrics: (reset: boolean) => void) => {
  const { messageId, requestSentAt, firstChunkAt, completedAt } = metricsData

  useEffect(() => {
    if (messageId && requestSentAt && firstChunkAt && completedAt) {
      doSendMetrics(true)
    }
  }, [messageId, requestSentAt, firstChunkAt, completedAt])
}
