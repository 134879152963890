/* eslint-disable */
import React from 'react'

export const IconSendMessage = ({ className, color = '#3a87f0', ...props }) => {
  return (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5821 14.7604C9.06234 14.7992 8.578 14.6119 8.2315 14.2654C7.92055 14.1402 6.18293 9.83782 5.98291 9.4924L9.30632 6.16899C9.5043 5.971 9.50077 5.65633 9.30632 5.46188C9.10833 5.26389 8.7972 5.26389 8.59921 5.46188L5.2758 8.78529L1.02608 6.89733C0.347263 6.59326 -0.0522318 5.92506 0.0043271 5.18258C0.0644251 4.44365 0.566486 3.84262 1.28065 3.65171L11.8236 0.823262C12.4282 0.66418 13.054 0.83034 13.4959 1.27228C13.9343 1.71068 14.1005 2.33648 13.9414 2.94104L11.113 13.484C10.9221 14.1982 10.321 14.7002 9.5821 14.7604Z"
        fill={color}
      />
    </svg>
  )
}

export const MinusIcon = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 12H19" stroke={color || 'white'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const MiniMazeIcon = ({ width, height, color }) => (
  <svg width={width || '22'} height={height || '22'} viewBox="0 0 24 24" fill="none">
    <path d="M4 14H10V20" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20 10H14V4" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 10L21 3" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 21L10 14" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const MaxiMazeIcon = ({ width, height, color }) => (
  <svg width={width || '22'} height={height || '22'} viewBox="0 0 24 24" fill="none">
    <path d="M3 15V21H9" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M21 9L21 3L15 3"
      stroke={color || '#616581'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M21 3L14 10" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 21L10 14" stroke={color || '#616581'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export const IconCloseConversationStarters = ({ fill = '#1b1b28' }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="12" height="12" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.283 2.28245C10.4391 2.12624 10.4391 1.87298 10.283 1.71677C10.1267 1.56056 9.87346 1.56056 9.71722 1.71677L6.0001 5.43392L2.28294 1.71677C2.12673 1.56056 1.87347 1.56056 1.71726 1.71677C1.56104 1.87298 1.56104 2.12624 1.71726 2.28245L5.43441 5.99961L1.71726 9.71673C1.56104 9.87297 1.56104 10.1263 1.71726 10.2825C1.87347 10.4387 2.12673 10.4387 2.28294 10.2825L6.0001 6.5653L9.71722 10.2825C9.87346 10.4387 10.1267 10.4387 10.283 10.2825C10.4391 10.1263 10.4391 9.87297 10.283 9.71673L6.56579 5.99961L10.283 2.28245Z"
        fill={fill}
      />
    </svg>
  )
}

export const IconBubble = () => {
  return (
    <svg width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.16904e-06 9.65701C-0.000845831 8.38778 0.246962 7.13086 0.729234 5.95819C1.21149 4.78555 1.91874 3.7202 2.81048 2.82316C3.70221 1.92613 4.76093 1.21503 5.92597 0.730604C7.09103 0.246159 8.33954 -0.00211312 9.6 1.35476e-05H22.4C27.7008 1.35476e-05 32 4.34196 32 9.65701V29H9.6C4.2992 29 2.16904e-06 24.6581 2.16904e-06 19.343V9.65701ZM28.8 25.7778V9.65701C28.7958 7.95004 28.1198 6.31435 26.9202 5.10823C25.7206 3.90212 24.0952 3.22393 22.4 3.22224H9.6C8.75976 3.22011 7.92736 3.38504 7.15056 3.70753C6.37375 4.03005 5.66781 4.50379 5.07322 5.10161C4.47863 5.69943 4.00709 6.40956 3.68565 7.19129C3.36419 7.973 3.19915 8.81094 3.2 9.65701V19.343C3.20424 21.05 3.88018 22.6857 5.07978 23.8918C6.27936 25.0979 7.9048 25.7762 9.6 25.7778H28.8ZM19.2 12.8889H22.4V16.1111H19.2V12.8889ZM9.6 12.8889H12.8V16.1111H9.6V12.8889Z"
        fill="white"
      />
    </svg>
  )
}

export const IconCloseBubble = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1381 1.80473C15.3984 1.54438 15.3984 1.12227 15.1381 0.861926C14.8777 0.601567 14.4556 0.601567 14.1952 0.861926L7.99999 7.05718L1.80473 0.861926C1.54438 0.601567 1.12227 0.601567 0.861926 0.861926C0.601567 1.12227 0.601567 1.54438 0.861926 1.80473L7.05718 7.99999L0.861926 14.1952C0.601567 14.4556 0.601567 14.8777 0.861926 15.1381C1.12227 15.3984 1.54438 15.3984 1.80473 15.1381L7.99999 8.94281L14.1952 15.1381C14.4556 15.3984 14.8777 15.3984 15.1381 15.1381C15.3984 14.8777 15.3984 14.4556 15.1381 14.1952L8.94281 7.99999L15.1381 1.80473Z"
        fill="white"
      />
    </svg>
  )
}
