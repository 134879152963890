import React, { FC, useEffect, useState } from 'react'
import App from './App'
import { useChat } from 'ai/react'
import { API_URL } from 'config/config'
import { getQuickReplies } from 'api/api'
import { createChatOptions } from 'helper/createChatOptions'
import { ActionTypeMap } from 'constants/index'
import { QuickReplies } from 'types'
import { useMetrics } from 'hooks/useMetrics/useMetrics'

interface AppContainerProps {
  initializeStorage: (websiteLocation: string, storage) => void
  updateBrowserStorage: (storage) => void
  browserStorage: Record<string, string | null | undefined>
}

const defaultQuickRepliesState = { messageId: '', quickReplies: [] }

export const AppContainer: FC<AppContainerProps> = ({ initializeStorage, updateBrowserStorage, browserStorage }) => {
  const [initialMessages, setInitialMessages] = useState([])
  const [quickReplies, setQuickReplies] = useState<QuickReplies>(defaultQuickRepliesState)
  const [currentUrl, setCurrentUrl] = useState<string | null>(null)

  const { firstChunkAt, setRequestSentAt, setFirstChunkAt, setCompletedAt, setMessageId } = useMetrics({
    botId: browserStorage?.botId,
    chatId: browserStorage?.chatId,
    backendURL: API_URL,
  })

  const { messages, input, handleInputChange, handleSubmit, isLoading, append } = useChat({
    api: `${API_URL}/api/public/bot/${browserStorage?.botId}/chat/${browserStorage?.chatId}`,
    headers: {
      'Content-Type': 'application/json',
      'x-widget': 'true',
    },
    body: {
      currentUrl,
    },
    initialMessages,
    onFinish(lastMessage) {
      setCompletedAt(new Date())
      handleQuickReplies(lastMessage.id)
    },
  })

  useEffect(() => {
    const lastMessage = messages[messages.length - 1]
    const customMessage = lastMessage as any
    const firstAnnotation = customMessage?.annotations?.[0]

    if (firstAnnotation?.messageId) {
      setMessageId(firstAnnotation.messageId)
    }
    if (!firstChunkAt && lastMessage?.role == 'assistant' && lastMessage?.content && isLoading) {
      console.log('setting firstChunkAt')
      setFirstChunkAt(new Date())
    }
  }, [messages, isLoading, firstChunkAt])

  const handleQuickReplies = (tempId: string) => {
    if (browserStorage?.quickRepliesEnabled) {
      getQuickReplies(browserStorage?.botId, browserStorage?.chatId, tempId).then(quickRepliesResponse => {
        setQuickReplies(quickRepliesResponse || defaultQuickRepliesState)
      })
    }
  }

  const submitMessage = e => {
    setRequestSentAt(new Date())
    browserStorage?.botId && localStorage.setItem(`widgetChatInitiated-${browserStorage.botId}`, 'true')
    handleSubmit(e, createChatOptions(ActionTypeMap.TEXT_MESSAGE))
  }

  return (
    <App
      initialMessages={initialMessages}
      setInitialMessages={setInitialMessages}
      messages={messages}
      quickReplies={quickReplies}
      setQuickReplies={setQuickReplies}
      input={input}
      isLoading={isLoading}
      handleInputChange={handleInputChange}
      handleSubmit={submitMessage}
      updateBrowserStorage={updateBrowserStorage}
      browserStorage={browserStorage}
      initializeStorage={initializeStorage}
      append={append}
      setCurrentUrl={setCurrentUrl}
      setRequestSentAt={setRequestSentAt}
    />
  )
}
