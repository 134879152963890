import { MutableRefObject, useEffect, useState } from 'react'

export const usePreviewHeight = (
  elementRef: MutableRefObject<HTMLDivElement | null>,
  isPopupsNeeded: boolean,
  websiteLocation?: string,
) => {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const element = elementRef.current

    if (!element) return

    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        setHeight(entry.contentRect.height)
      }
    })

    resizeObserver.observe(element)

    return () => resizeObserver.disconnect()
  }, [])

  useEffect(() => {
    if (isPopupsNeeded && websiteLocation) {
      window.parent.postMessage(
        {
          type: 'update-widget-height',
          width: '380px',
          height: `${height + 84}px`,
        },
        websiteLocation,
      )
    }
  }, [height])
}
