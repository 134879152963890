import React, { useContext, useEffect, useRef, useState, JSX } from 'react'
import MessageGroup from 'components/message/MessageGroup'
import { Typing } from 'components/message/Typing/Typing'
import { ChatRoleMap } from 'constants/index'
import { ScrollToButtonContext } from 'context/ScrollToButtonContext'

import { usePrevious } from 'hooks/usePrevious'

import '../../message/Message.css'
import { messageContainerSize } from './config'
import classes from './styles.module.scss'
import { ChatScrollAnchor } from './ChatScrollAnchor'
import { ConversationStarters } from './ConversationStarters'
import { getColorByContrast } from 'helper/contrast'
import { findLast } from 'helper/findLast'
import { Message } from 'ai'

const MessageContainer = (props): JSX.Element => {
  const {
    postMessage,
    textDisabled,
    messages,
    quickRepliesEnabled,
    quickReplies,
    setQuickReplies,
    parentURL,
    device,
    settings,
    isFullScreenWidget,
    openWidget,
    isMobile,
    screenSize,
    isLoading,
    conversationStarters,
    hideConversationStarters,
    append,
    trackWidgetEvent,
    setRequestSentAt,
  } = props
  const lastMessageContentLength = messages[messages.length - 1]?.content?.length
  const [messagesGroups, setMessagesGroups] = useState([])
  const { scrollBehavior } = useContext(ScrollToButtonContext)

  const scrollContainer = useRef(null)
  const prevMessagesLength = usePrevious(lastMessageContentLength)
  const containerSize = messageContainerSize(device, textDisabled, isFullScreenWidget, scrollBehavior, screenSize)
  useEffect(() => {
    if (prevMessagesLength !== lastMessageContentLength) {
      updateMessagesGroups()
    }
  }, [lastMessageContentLength])

  useEffect(() => {
    if (openWidget && scrollContainer.current) {
      scrollContainer.current.scrollTo({ top: scrollContainer.current.scrollHeight, behavior: 'smooth' })
    }
  }, [openWidget, scrollContainer.current, messagesGroups.length, quickReplies?.quickReplies])

  // TODO Refactor message grouping. We don't need it for now.
  // We have a straightforward sequence of assistant/user messages
  const updateMessagesGroups = () => {
    let messagesGroups = []
    let groupBot = []
    let groupUser = []

    messages.forEach((msg, index) => {
      // do not display tool invocations (empty assistant message), or
      //  reply is not done generating when user fetches chat history.
      //  This edge-case is described here: https://botscrew.atlassian.net/browse/NFA-1100)
      if (msg.toolInvocations || !msg?.content?.trim()) {
        return
      }
      if (msg?.role === ChatRoleMap.assistant) {
        groupBot = [...groupBot, msg]
      } else if (msg?.role === ChatRoleMap.user) {
        groupUser = [...groupUser, msg]
      }

      if (
        msg?.role === ChatRoleMap.assistant &&
        (!messages[index + 1] || messages[index + 1]?.role !== ChatRoleMap.assistant)
      ) {
        messagesGroups = [...messagesGroups, groupBot]
        groupBot = []
      } else if (
        msg?.role === ChatRoleMap.user &&
        (!messages[index + 1] || messages[index + 1]?.role === ChatRoleMap.assistant)
      ) {
        messagesGroups = [...messagesGroups, groupUser]
        groupUser = []
      }
    })
    setMessagesGroups(messagesGroups)
  }

  const lastGroupMessages = messagesGroups[messagesGroups?.length - 1]
  const lastMessageInGroup = lastGroupMessages && lastGroupMessages[lastGroupMessages?.length - 1]
  const isLastUserMessage =
    lastGroupMessages && lastGroupMessages[lastGroupMessages.length - 1]?.role === ChatRoleMap.user
  const isLastAssistantMessage =
    lastGroupMessages && lastGroupMessages[lastGroupMessages.length - 1]?.role === ChatRoleMap.assistant
  const lastAssistantMessageIsEmpty = findLast(messages, (msg: Message) => msg.role === 'assistant')?.content === ''
  const showLoadingTyping = isLoading && (isLastUserMessage || lastAssistantMessageIsEmpty)
  const isBotReplying = isLoading && !isLastUserMessage

  return (
    <div ref={scrollContainer} className={classes.msgWrapContainer} style={containerSize}>
      {messagesGroups.slice().map((group, groupIndex) => {
        const isLastGroup = messagesGroups.length === groupIndex + 1
        const showQuickReplies =
          quickRepliesEnabled &&
          isLastAssistantMessage &&
          isLastGroup &&
          !isLoading &&
          lastMessageInGroup.id === quickReplies.messageId

        return (
          <MessageGroup
            postMessage={msg => postMessage(msg)}
            key={groupIndex}
            quickReplies={quickReplies.quickReplies}
            setQuickReplies={setQuickReplies}
            showQuickReplies={showQuickReplies}
            trackWidgetEvent={trackWidgetEvent}
            group={group}
            device={device}
            parentURL={parentURL}
            settings={settings}
            isMobile={isMobile}
            isFullScreenWidget={isFullScreenWidget}
            isLastGroup={isLastGroup}
            isBotReplying={isBotReplying}
            append={append}
            setRequestSentAt={setRequestSentAt}
          />
        )
      })}
      {!hideConversationStarters && (
        <ConversationStarters
          conversationStarters={conversationStarters}
          actionColor={settings.color}
          backgroundColor={settings.backgroundColor}
          append={append}
          borderRadius={settings.shape?.radius}
          trackWidgetEvent={trackWidgetEvent}
          setRequestSentAt={setRequestSentAt}
        />
      )}
      {showLoadingTyping && (
        <Typing actionColor={getColorByContrast(settings.backgroundColor, settings.color)} logo={settings.logo} />
      )}

      <ChatScrollAnchor trackVisibility={isLoading} scrollContainer={scrollContainer} />
    </div>
  )
}

export default MessageContainer
