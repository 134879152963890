import { Maybe } from './types'

export function findLast<T>(
  array: Maybe<T[]>,
  callback: (value: T, index: number, array: T[]) => boolean,
  thisArg?: any,
): Maybe<T> {
  if (!array) {
    return undefined
  }
  if (!Array.isArray(array)) {
    throw new TypeError('Expected an array')
  }

  for (let i = array.length - 1; i >= 0; i--) {
    if (callback.call(thisArg, array[i], i, array)) {
      return array[i]
    }
  }

  return undefined
}
