import styled from 'styled-components'
import { _10PercentTransparencyInHex, _20PercentTransparencyInHex } from 'constants/colors'

const QuickReplyList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  gap: 4px;
  justify-content: flex-end;
`
const QuickReplyItem = styled.div<{ actionColor: string; borderRadius: number; color: string }>`
  font-size: 14px;
  line-height: 16px;
  padding: 8px 16px;
  color: ${({ color }) => color};
  border: ${({ actionColor }) => `1px solid ${actionColor}${_20PercentTransparencyInHex}`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
  cursor: pointer;
  &:hover {
    background: ${({ actionColor }) => `${actionColor}${_10PercentTransparencyInHex}`};
  }
`

export { QuickReplyList, QuickReplyItem }
