import { DARK_JUNGLE_GREEN, WHITE } from 'constants/colors'
import contrast from 'get-contrast'

export const getColorByContrast = (bgColor: string, actionColor?: string): string => {
  const limitRatioForLightBackground = 1.3

  if (!bgColor) {
    return DARK_JUNGLE_GREEN
  }

  if (actionColor && contrast.ratio(bgColor, WHITE) <= limitRatioForLightBackground) {
    return actionColor
  }

  const lightColorRatio = contrast.ratio(bgColor, WHITE)
  const darkColorRatio = contrast.ratio(bgColor, DARK_JUNGLE_GREEN)

  return darkColorRatio > lightColorRatio ? DARK_JUNGLE_GREEN : WHITE
}
