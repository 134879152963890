import React, { useContext } from 'react'
import MessageBody from '../MessageBody'

import { checkShowLogo, defineMessageBorder } from '../../containers/Chat/config'
import { AriaLiveContext } from 'context/AriaLiveContext'
import { usePreventFocus } from 'hooks/usePreventFocus'

import * as S from './MessageGroup.style'

const MessageGroup = ({
  group,
  device,
  quickReplies,
  setQuickReplies,
  showQuickReplies,
  parentURL,
  settings,
  isMobile,
  isFullScreenWidget,
  isLastGroup,
  postMessage,
  isBotReplying,
  append,
  trackWidgetEvent,
  setRequestSentAt,
}) => {
  const { isEnableAriaLive } = useContext(AriaLiveContext)
  const preventFocus = usePreventFocus()

  return (
    <S.MessageGroup
      aria-live={isEnableAriaLive && group[0].isFromBot && isLastGroup && !preventFocus ? 'assertive' : 'off'}
      aria-atomic="true">
      {group
        ?.filter(message => !message.toolInvocations)
        .map((message, msgIndex) => (
          <MessageBody
            key={message.id || msgIndex}
            device={device}
            message={message}
            quickReplies={quickReplies}
            setQuickReplies={setQuickReplies}
            showQuickReplies={showQuickReplies}
            trackWidgetEvent={trackWidgetEvent}
            append={append}
            parentURL={parentURL}
            settings={settings}
            isMobile={isMobile}
            isFullScreenWidget={isFullScreenWidget}
            showLogo={checkShowLogo(group, msgIndex)}
            messageBorder={defineMessageBorder(group, msgIndex)}
            postMessage={msg => postMessage(msg)}
            isLastMessage={isLastGroup && group.length === msgIndex + 1}
            isBotReplying={isBotReplying}
            setRequestSentAt={setRequestSentAt}
          />
        ))}
    </S.MessageGroup>
  )
}

export default MessageGroup
