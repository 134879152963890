import { useEffect, useRef } from 'react'

export function usePrevious(value: number | undefined): number | undefined {
  const ref = useRef<number>()
  useEffect(() => {
    if (value !== undefined && ref.current !== value) {
      ref.current = value
    }
  })
  return ref.current
}
