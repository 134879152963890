import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react'

import Textarea from 'react-textarea-autosize'
import { SettingsType } from 'models/SettingsType'
import { DeviceType } from 'models/DeviceType'
import { IconSendMessage } from 'icons/Icons'
import { usePreventFocus } from 'hooks/usePreventFocus'
import { INPUT_OFF } from 'constants/actionsType'
import { _20PercentTransparencyInHex } from 'constants/colors'

import * as S from './UserInput.style'
import { UseChatHelpers } from 'ai/react/dist'
import { QuickReplies } from 'types'
import { getColorByContrast } from 'helper/contrast'

interface Props {
  textDisabled: boolean
  inputTogglerValue: string
  settings: SettingsType
  isFullScreenWidget: boolean
  isRtl: boolean
  chatId: number
  device: DeviceType
  showIframe: boolean
  postMessage: (m: any) => void
  screenSize: DeviceType
  disableInput: boolean
  input: string
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
  handleSubmit: UseChatHelpers['handleSubmit']
  setQuickReplies: Dispatch<SetStateAction<QuickReplies>>
  isMobile?: boolean
}

const enterKeyCode = 13
const isEmptyInput = (inputValue: string | undefined | null) => !inputValue || !inputValue.trim()

export const UserInput: React.FC<Props> = ({
  textDisabled,
  inputTogglerValue,
  settings,
  isRtl,
  disableInput,
  input,
  isMobile,
  handleInputChange,
  handleSubmit,
  setQuickReplies,
}) => {
  const inputRef = useRef(null)
  const preventFocus = usePreventFocus()
  const isHiddenTextInput = useMemo(() => inputTogglerValue === INPUT_OFF, [inputTogglerValue])

  const handleUserKeyPress = useCallback(event => {
    const isPrintableKey = event.key.length === 1 && !event.metaKey && !event.ctrKey

    if (document.activeElement !== inputRef.current && isPrintableKey) {
      inputRef.current.focus()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  const handleEnter = e => {
    if (e.charCode === enterKeyCode && !disableInput) {
      e.preventDefault()
      if (isHiddenTextInput) return
      handleSendMessage(e)
    }
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    handleInputChange(e)
  }

  const handleSendMessage = (e: FormEvent<HTMLFormElement>) => {
    if (isEmptyInput(input)) return
    setQuickReplies({ messageId: '', quickReplies: [] })
    handleSubmit(e)
  }

  const textColor = getColorByContrast(settings.backgroundColor)
  const actionColor = getColorByContrast(settings.backgroundColor, settings.color)

  return (
    <S.UserInput textDisabled={textDisabled} isMobile={isMobile} textColor={textColor} actionColor={actionColor}>
      <Textarea
        maxRows={2}
        maxLength={2000}
        placeholder={settings?.inputPlaceholder}
        dir={isRtl ? 'rtl' : 'ltr'}
        id="messaging-user-input"
        autoComplete="off"
        value={input}
        onChange={handleChange}
        onKeyPress={handleEnter}
        tabIndex={preventFocus ? -1 : 0}
        data-testid="input"
        ref={inputRef}
        color={settings.color}
        style={{
          background: settings.backgroundColor || '#fff',
          width: '100%',
          visibility: isHiddenTextInput ? 'hidden' : 'visible',
          boxSizing: 'border-box',
          padding: '4px 16px',
          borderRadius: `${settings.shape.radius}px`,
          color: textColor,
          border: `1px solid ${actionColor}${_20PercentTransparencyInHex}`,
          resize: 'none',
          opacity: isHiddenTextInput ? 0 : 1,
          outline: '0',
          paddingRight: 38,
          height: 40,
          lineHeight: '30px',
        }}
      />
      <S.ButtonWrapper>
        <S.Button
          onClick={handleSendMessage}
          aria-label="send message"
          tabIndex={preventFocus ? -1 : 0}
          data-testid="button"
          isHiddenTextInput={isHiddenTextInput}
          disabled={disableInput}
          radius={settings.shape.radius}
          isMobile={isMobile}
          actionColor={actionColor}>
          <IconSendMessage width={21} height={21} color={`${actionColor}${_20PercentTransparencyInHex}`} />
        </S.Button>
      </S.ButtonWrapper>
    </S.UserInput>
  )
}
