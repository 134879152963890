import React, { JSX, Dispatch, SetStateAction } from 'react'

import * as S from './QuickReplyList.style'
import { ActionTypeMap, ChatRoleMap } from 'constants/index'
import { Append, QuickReplies } from 'types'
import { createChatOptions } from 'helper/createChatOptions'
import { QUICK_REPLY_CLICKED, WidgetEvent, createEvent } from 'helper/eventHelper'
import { getColorByContrast } from 'helper/contrast'

interface QuickReplyListProps {
  quickReplies: string[]
  color: string
  backgroundColor: string
  borderRadius: number
  append: Append
  setQuickReplies: Dispatch<SetStateAction<QuickReplies>>
  trackWidgetEvent: (event: WidgetEvent) => void
  setRequestSentAt: Dispatch<SetStateAction<Date | null>>
}

export const QuickReplyList = ({
  quickReplies,
  color,
  backgroundColor,
  borderRadius,
  append,
  setQuickReplies,
  trackWidgetEvent,
  setRequestSentAt,
}: QuickReplyListProps): JSX.Element => {
  const handleClickQuickReply = (reply: string) => {
    setQuickReplies({ messageId: '', quickReplies: [] })
    trackWidgetEvent(createEvent(QUICK_REPLY_CLICKED, reply))
    setRequestSentAt(new Date())
    append({ content: reply, role: ChatRoleMap.user }, createChatOptions(ActionTypeMap.QUICK_REPLY))
  }
  return (
    <S.QuickReplyList>
      {quickReplies?.map(reply => (
        <S.QuickReplyItem
          onClick={() => handleClickQuickReply(reply)}
          actionColor={getColorByContrast(backgroundColor, color)}
          color={getColorByContrast(backgroundColor)}
          borderRadius={borderRadius}
          key={reply}>
          {reply}
        </S.QuickReplyItem>
      ))}
    </S.QuickReplyList>
  )
}
