import styled from 'styled-components'
import { _15PercentTransparencyInHex, _50PercentTransparencyInHex, _80PercentTransparencyInHex } from 'constants/colors'

const UserInput = styled.div<{ actionColor: string; textDisabled?: boolean; textColor: string; isMobile?: boolean }>`
  display: ${({ textDisabled }) => (textDisabled ? 'flex' : 'none')};
  position: relative;
  margin-left: 24px;
  margin-right: 24px;

  & textarea {
    font-size: ${({ isMobile }) => (isMobile ? '16px' : '14px')};

    &::placeholder {
      color: ${({ textColor }) => `${textColor}${_50PercentTransparencyInHex}`};
    }

    &:hover {
      border-color: ${({ actionColor }) => `${actionColor}${_80PercentTransparencyInHex}`} !important;
    }

    &:focus {
      border-color: ${({ actionColor }) => `${actionColor}${_80PercentTransparencyInHex}`} !important;

      & + div > button > svg path {
        fill: ${({ actionColor }) => actionColor};
      }
    }
  }
`

const Button = styled.button<{ isMobile?: boolean; actionColor: string; radius: number }>`
  width: 32px;
  height: 32px;
  outline: none;
  border: none;
  padding: 8px;
  border-radius: ${({ radius }) => radius}px;

  background: none;
  opacity: ${props => (props.isHiddenTextInput ? 0 : 1)};
  visibility: ${props => (props.isHiddenTextInput ? 'hidden' : 'visible')};
  cursor: pointer;

  &:hover {
    background-color: ${({ actionColor }) => actionColor + _15PercentTransparencyInHex};
  }
`

const ButtonWrapper = styled.div`
  right: 5.5px;
  bottom: 4px;
  position: absolute;
  background: transparent;
`

export { UserInput, Button, ButtonWrapper }
