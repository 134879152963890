import React, { JSX } from 'react'

import { _10PercentTransparencyInHex, _20PercentTransparencyInHex } from 'constants/colors'
import { ActionTypeMap, ChatRoleMap } from 'constants/index'

import classes from './styles.module.scss'
import { CONVERSATION_STARTER_CLICKED, WidgetEvent, createEvent } from 'helper/eventHelper'
import { Append } from 'types'
import { createChatOptions } from 'helper/createChatOptions'
import { getColorByContrast } from 'helper/contrast'

export type ConversationStarter = {
  id: string
  text: string
  reply: string
}

interface ConversationStarersProps {
  conversationStarters: ConversationStarter[]
  actionColor?: string
  backgroundColor: string
  append: Append
  trackWidgetEvent: (event: WidgetEvent) => void
  setRequestSentAt: React.Dispatch<React.SetStateAction<Date>>
  borderRadius: number
}

export const ConversationStarters = ({
  conversationStarters,
  actionColor,
  backgroundColor,
  borderRadius,
  append,
  trackWidgetEvent,
  setRequestSentAt,
}: ConversationStarersProps): JSX.Element => {
  const handleConversationStarterClick = (starter: ConversationStarter) => {
    const starterText = starter.text
    trackWidgetEvent(createEvent(CONVERSATION_STARTER_CLICKED, starterText))
    setRequestSentAt(new Date())
    append(
      {
        content: starterText,
        role: ChatRoleMap.user,
        annotations: [{ type: 'conversationStarter', conversationStarter: starter }],
      },
      createChatOptions(ActionTypeMap.CONVERSATION_STARTER),
    )
  }

  const interactionColor = getColorByContrast(backgroundColor, actionColor)

  const inlineStyles = {
    borderRadius: `${borderRadius}px`,
    border: `1px solid ${interactionColor}${_20PercentTransparencyInHex}`,
    backgroundColor,
    color: getColorByContrast(backgroundColor),
    //@ts-ignore
    '--hoverBg': `${interactionColor}${_10PercentTransparencyInHex}`,
  }

  return (
    <div className={classes.conversationStarterContainer}>
      {conversationStarters.map(starterMsg => (
        <div key={starterMsg.id} className={classes.conversationItem}>
          <button
            onClick={() => handleConversationStarterClick(starterMsg)}
            className={classes.actionButton}
            style={inlineStyles}>
            {starterMsg.text}
          </button>
        </div>
      ))}
    </div>
  )
}
