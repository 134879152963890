import React, { JSX } from 'react'
import './Typing.css'
import { _50PercentTransparencyInHex } from 'constants/colors'

interface TypingProps {
  logo?: string
  actionColor: string
}

export const Typing = ({ logo, actionColor }: TypingProps): JSX.Element => {
  return (
    <div className="typingContainer">
      <img src={logo || '/defaultBotLogo.svg'} width={24} height={24} alt="Typing bot logo" className="typingLogo" />
      <div className="chat__bubble chat__bubble-typing">
        <div
          className="loading"
          style={{
            // @ts-ignore
            '--actionColor': actionColor,
            '--mutedActionColor': `${actionColor}${_50PercentTransparencyInHex}`,
          }}>
          <div className="dot one"></div>
          <div className="dot two"></div>
          <div className="dot three"></div>
        </div>
      </div>
    </div>
  )
}
