import React, { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react'
import { UseChatHelpers } from 'ai/react/dist'

import Chat from '../Chat/Chat'
import Header from 'components/Header'
import { styleByScreenSize } from './configs'

import classes from './styles.module.scss'
import 'app/App.css'
import { SettingsType } from 'models/SettingsType'
import { DeviceType } from 'models/DeviceType'
import { MessageType } from 'models/MessageType'
import { Append, QuickReplies } from 'types'
import { WidgetEvent } from 'helper/eventHelper'
import { ConversationStarter } from '../Chat/ConversationStarters'

interface Props {
  screenSize: DeviceType
  device: DeviceType
  isMobile: boolean
  settings: SettingsType
  parentURL: string
  messages: MessageType[]
  quickRepliesEnabled: boolean
  quickReplies: QuickReplies
  setQuickReplies: Dispatch<SetStateAction<QuickReplies>>
  isHiddenWidget: boolean
  openWidget: boolean
  textDisabled: boolean
  inputTogglerValue: string
  toggleWidget: () => void
  isFullScreenWidget: boolean
  handleHiddenWidget: () => void
  handleSoundNotification: () => void
  input: string
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void
  handleSubmit: UseChatHelpers['handleSubmit']
  isLoading: boolean
  conversationStarters: ConversationStarter[]
  hideConversationStarters: boolean
  append: Append
  trackWidgetEvent: (event: WidgetEvent) => void
  setRequestSentAt: Dispatch<SetStateAction<Date | null>>
}

const Container: React.FC<Props> = props => {
  const {
    device,
    isMobile,
    settings,
    parentURL,
    messages,
    quickRepliesEnabled,
    quickReplies,
    setQuickReplies,
    isHiddenWidget,
    trackWidgetEvent,
    openWidget,
    textDisabled,
    inputTogglerValue,
    isFullScreenWidget,
    handleHiddenWidget,
    screenSize,
    input,
    handleInputChange,
    handleSubmit,
    isLoading,
    conversationStarters,
    hideConversationStarters,
    append,
    setRequestSentAt,
  } = props
  const [openPopover, setOpenPopover] = useState(false)
  const popoverRef = useRef(null)
  const parentRef = useRef(null)

  const containerStyle = useMemo(() => {
    return device && styleByScreenSize(isFullScreenWidget, device.width, device.height, isMobile, screenSize)
  }, [isFullScreenWidget, device, isMobile, screenSize])

  const handleOpenPopover = () => {
    setOpenPopover(!openPopover)
  }

  return (
    <div>
      <div
        className={classes.container}
        style={{
          ...containerStyle,
          borderRadius: isMobile ? '0px' : `${settings.shape.radius}px`,
          background: settings.backgroundColor,
        }}>
        {settings && (
          <Header
            widgetLogo={settings.logo || '/defaultBotLogo.svg'}
            header={settings.header}
            widgetColor={settings.color}
            backgroundColor={settings.backgroundColor}
            borderRadius={settings.shape?.radius}
            isFullScreenWidget={isFullScreenWidget}
            popoverRef={popoverRef}
            parentRef={parentRef}
            openPopover={openPopover}
            handleHiddenWidget={handleHiddenWidget}
            handleOpenPopover={handleOpenPopover}
            isMobile={isMobile}
          />
        )}

        <Chat
          input={input}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          screenSize={screenSize}
          device={device}
          isMobile={isMobile}
          messages={messages}
          quickRepliesEnabled={quickRepliesEnabled}
          quickReplies={quickReplies}
          setQuickReplies={setQuickReplies}
          trackWidgetEvent={trackWidgetEvent}
          isHiddenWidget={isHiddenWidget}
          openWidget={openWidget}
          settings={settings}
          parentURL={parentURL}
          textDisabled={textDisabled}
          inputTogglerValue={inputTogglerValue}
          isFullScreenWidget={isFullScreenWidget}
          conversationStarters={conversationStarters}
          hideConversationStarters={hideConversationStarters}
          append={append}
          setRequestSentAt={setRequestSentAt}
        />
      </div>
    </div>
  )
}

export default Container
