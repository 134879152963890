/* eslint-disable no-confusing-arrow */
import styled from 'styled-components'
import { _20PercentTransparencyInHex, _10PercentTransparencyInHex } from 'constants/colors'

const MessagesPreview = styled.div<{
  isPopupsNeeded: string
  alignItems: string
  borderRadius: number
  backgroundColor: string
}>`
  display: ${({ isPopupsNeeded }) => (isPopupsNeeded ? 'flex' : 'none')};
  flex-direction: column;
  align-items: ${({ alignItems }) => alignItems};
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: ${({ borderRadius }) => borderRadius}px;
  padding: 8px 12px 24px 8px;
  margin-right: ${({ widgetXPosition }) => (widgetXPosition === 'right' ? '12px' : '0')};
  margin-left: ${({ widgetXPosition }) => (widgetXPosition === 'left' ? '12px' : '0')};
  cursor: pointer;
`

const Popup = styled.button<{
  width: number
  isMobile: boolean
  hideConversationStarters: boolean
  widgetXPosition: string
  backgroundColor: string
}>`
  display: flex;
  border-radius: 4px;
  align-items: center;
  animation: toggleOpacity 0.7s;
  cursor: pointer;
  max-width: ${({ width }) => (width ? width - 64 : 380)}px;
  margin: ${({ hideConversationStarters }) => (hideConversationStarters ? '0 0 0 0' : '0 0 16px 0')};
  border: none;
  position: relative;
  min-height: 48px;
  padding: 0;
  align-self: flex-start;
  background: ${({ backgroundColor }) => backgroundColor};

  & > p {
    padding-right: 12px;
  }
`

const Icon = styled.span`
  cursor: pointer;
  align-items: center;
  border: none;
  border-radius: 50%;
  padding: 0;
`

const Text = styled.p<{ color: string; width: number }>`
  font-size: 14px;
  color: ${({ color }) => color};
  margin: 0;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: ${({ width }) => (width ? 'auto' : '320px')};
  word-break: break-word;
  width: ${({ width }) => (width ? 'auto' : '320px')};
  padding: 8px 16px;
  text-align: left;
  padding-bottom: 0;
`

const ConvoWrapper = styled.div<{ width: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: flex-end;
  width: 100%;
  max-width: ${({ width }) => (width ? width - 64 : 380)}px;
`

const ConversationStarter = styled.div<{ width: number; borderRadius: number; actionColor: string }>`
  display: flex;
  box-shadow: ${({ width }) => (width ? '0px 0px 13px rgba(51, 51, 51, 0.1)' : '0px 0px 30px rgba(51, 51, 51, 0.1)')};
  align-items: center;
  cursor: pointer;
  max-width: ${({ width }) => (width ? `${width - 84}px` : 'fit-content')};
  min-height: 32px;
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius}px;

  &:hover {
    background: ${({ actionColor }) => `${actionColor}${_10PercentTransparencyInHex}`};
  }
`

const ConversationStarterText = styled.p<{ actionColor: string; borderRadius: number; color: string }>`
  font-size: 14px;
  color: ${({ color }) => color};
  margin: 0;
  text-overflow: ellipsis;
  overflow-x: hidden;
  word-break: break-word;
  padding: 8px 16px;
  text-align: left;
  line-height: 19px;
  border: ${({ actionColor }) => `1px solid ${actionColor}${_20PercentTransparencyInHex}`};
  border-radius: ${({ borderRadius }) => borderRadius}px;
`

const ConversationStarterContainer = styled.div<{ width: number; widgetXPosition: string; backgroundColor: string }>`
  background: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
  margin-left: 0;
  margin-right: 0;
  align-self: end;
`

const Header = styled.h6<{ color: string; width: number }>`
  color: ${({ color }) => color};
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  display: inline-block;
  font-size: 14px;
  max-width: ${({ width }) => (width ? width - 84 : 380)}px;
`

const HeaderContainer = styled.div`
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const HeaderLogo = styled.img.attrs(props => ({
  src: props.src || '/defaultBotLogo.svg',
}))`
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 100%;
`

export {
  MessagesPreview,
  Popup,
  Icon,
  Text,
  ConversationStarter,
  ConversationStarterContainer,
  Header,
  HeaderContainer,
  HeaderLogo,
  ImageContainer,
  ConversationStarterText,
  ConvoWrapper,
}
