import { v4 as uuidv4 } from 'uuid'

export const usPhoneNumberPattern = /(1\s?)?[(]?\d{3}[)]?[(\s)?-]\d{3}[\s-]\d{4}/gi
export const emailPattern = /(?:\*\*)?[^\s@]+@[^\s@]+\.[^\s@]+(?:\*\*)?/gi

export const isEmptyString = (text: string): boolean => {
  if (text == null) return true
  text = text.trim()
  return text.length === 0 && !text.trim() && text.trim() !== ' '
}

export const getQueryVariable = (variable: string): string | boolean => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (const element of vars) {
    const pair = element.split('=')
    if (pair[0] === variable) {
      return decodeURI(pair[1])
    }
  }
  return false
}

export const isElementTop = (el: HTMLElement | null): boolean => el && !el.scrollTop

export const generateWidgetChatId = (): string => {
  const uuid = uuidv4()
  const mostSignificantBits = BigInt('0x' + uuid.replace(/-/g, '').substring(0, 12))
  return mostSignificantBits.toString()
}
