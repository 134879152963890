import { useEffect } from 'react'
import { MetricsData } from './useMetrics'

export const useRequestSent = (metricsData: MetricsData, doSendMetrics: (reset: boolean) => void) => {
  const { messageId, requestSentAt, firstChunkAt } = metricsData

  useEffect(() => {
    if (messageId && requestSentAt && firstChunkAt) {
      doSendMetrics(false)
    }
  }, [messageId, requestSentAt, firstChunkAt])
}
