import React from 'react'
import { MinusIcon } from 'icons/Icons'
import * as S from './Header.style'
import { getColorByContrast } from 'helper/contrast'

interface HeaderProps {
  parentRef: any
  popoverRef: any
  header: string
  widgetColor: string
  widgetLogo: string
  isFullScreenWidget: boolean
  openPopover: boolean
  handleHiddenWidget: () => void
  handleOpenPopover: () => void
  borderRadius: number
  backgroundColor: string
  isMobile: boolean
}

export const Header: React.FC<HeaderProps> = ({
  parentRef,
  header,
  widgetColor,
  widgetLogo,
  borderRadius,
  isFullScreenWidget,
  backgroundColor,
  isMobile,
  handleHiddenWidget,
}) => {
  const textColor = getColorByContrast(backgroundColor)

  return (
    <S.Header
      ref={parentRef}
      isMobile={isMobile}
      color={widgetColor}
      isFullScreenWidget={isFullScreenWidget}
      borderRadius={borderRadius}>
      <S.NameWrap>
        <S.Logo src={widgetLogo} alt={header} />
        <S.Name tabIndex={0} isMobile={isMobile} color={textColor}>
          {header}
        </S.Name>
      </S.NameWrap>
      <S.ButtonsWrap>
        <S.Button onClick={handleHiddenWidget} id="wrap-widget-icon" aria-label="wrap widget" tabIndex={0}>
          <MinusIcon color={textColor} />
        </S.Button>
      </S.ButtonsWrap>
    </S.Header>
  )
}
