import React, { useRef } from 'react'
import { IconCloseConversationStarters } from 'icons/Icons'
import * as S from './MessagesPreview.style'
import { ActionTypeMap, ChatRoleMap } from '../../constants'
import { createEvent, POPUP_CLOSE, POPUP_OPEN, STARTER_OPEN } from '../../helper/eventHelper'

import { unreadIconDelay } from '../../app/config'
import { getColorByContrast } from 'helper/contrast'
import { usePreviewHeight } from './usePreviewHeight'
import { createChatOptions } from 'helper/createChatOptions'
import { ConversationStarter } from 'components/containers/Chat/ConversationStarters'

export const MessagesPreview = ({
  device,
  isMobile,
  greeting,
  isPopupsNeeded,
  openWidget,
  setShowPopup,
  setShowUnreadIcon,
  setUnreadIconTimeoutId,
  conversationStarters,
  settings,
  append,
  hideConversationStarters,
  conversationStarted,
  trackWidgetEvent,
  alignItems,
  widgetXPosition,
  resizeWidget,
  wasAutomaticallyOpened,
  setRequestSentAt,
  assistantName,
  websiteLocation,
}) => {
  const handleClosePopup = e => {
    trackWidgetEvent(createEvent(POPUP_CLOSE, greeting))
    e.stopPropagation()
    setShowPopup(false)
    resizeWidget()
    const unreadIconTimeoutId = setTimeout(() => setShowUnreadIcon(true), unreadIconDelay)
    setUnreadIconTimeoutId(unreadIconTimeoutId)
  }

  const handlePreviewClick = () => {
    trackWidgetEvent(createEvent(POPUP_OPEN, greeting))
    openWidget()
  }

  const handleConversationStarterClick = (e: Event, conversationStarter: ConversationStarter) => {
    e.stopPropagation()
    trackWidgetEvent(createEvent(STARTER_OPEN, conversationStarter.text))
    openWidget()
    setRequestSentAt(new Date())
    append(
      {
        content: conversationStarter.text,
        role: ChatRoleMap.user,
        annotations: [{ type: 'conversationStarter', conversationStarter }],
      },
      createChatOptions(ActionTypeMap.CONVERSATION_STARTER),
    )
  }

  const showGreetingsAndConvoStarters = isPopupsNeeded && !conversationStarted && !wasAutomaticallyOpened

  const textColor = getColorByContrast(settings.backgroundColor)
  const actionColor = getColorByContrast(settings.backgroundColor, settings.color)

  const elementRef = useRef<HTMLDivElement>(null)

  usePreviewHeight(elementRef, isPopupsNeeded, websiteLocation)

  return (
    <div ref={elementRef}>
      <S.MessagesPreview
        borderRadius={settings.shape?.radius}
        alignItems={alignItems}
        isPopupsNeeded={showGreetingsAndConvoStarters}
        widgetXPosition={widgetXPosition}
        backgroundColor={settings.backgroundColor}
        onClick={handlePreviewClick}>
        <S.HeaderContainer>
          <S.ImageContainer>
            <S.HeaderLogo src={settings.logo} alt="botLogo" width={24} height={24} />
            <S.Header width={device.width} color={textColor}>
              {assistantName}
            </S.Header>
          </S.ImageContainer>

          <S.Icon
            aria-label="close popup - button"
            id="closePopup"
            data-testid="toggle"
            tabIndex={0}
            onClick={handleClosePopup}>
            <IconCloseConversationStarters fill={textColor} />
          </S.Icon>
        </S.HeaderContainer>
        <S.Popup
          width={device.width}
          isMobile={isMobile}
          tabIndex={0}
          backgroundColor={settings.backgroundColor}
          hideConversationStarters={hideConversationStarters}>
          <S.Text width={device.width} color={textColor}>
            {greeting}
          </S.Text>
        </S.Popup>
        {!hideConversationStarters && (
          <S.ConvoWrapper width={device.width}>
            {conversationStarters.map((starterMessage: ConversationStarter, index: number) => (
              <S.ConversationStarterContainer
                widgetXPosition={widgetXPosition}
                key={starterMessage.id + index}
                width={device.width}
                backgroundColor={settings.backgroundColor}>
                <S.ConversationStarter
                  actionColor={actionColor}
                  width={device.width}
                  tabIndex={0}
                  borderRadius={settings.shape?.radius}
                  onClick={e => handleConversationStarterClick(e, starterMessage)}>
                  <S.ConversationStarterText
                    borderRadius={settings.shape?.radius}
                    color={textColor}
                    actionColor={actionColor}>
                    {starterMessage.text}
                  </S.ConversationStarterText>
                </S.ConversationStarter>
              </S.ConversationStarterContainer>
            ))}
          </S.ConvoWrapper>
        )}
      </S.MessagesPreview>
    </div>
  )
}
